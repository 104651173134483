/* @flow */
import React from 'react';

import classNames from 'classnames';
import { Marker } from 'react-map-gl';
import { MapMarkerUserSearch } from '@pluralcom/blueprint';

import type { BaseUserType } from '../../../../../../flow';

import styles from './MapMarkerUserSearchPublic.module.scss';

type Props = {
  className: ?string,
  onClick: ?Function,
  zIndex: ?string | number,
  zIndexHover: ?string | number,
  title?: string,
  isPublic: boolean,
  user?: BaseUserType,
  lat?: number,
  lng?: number,
};

const MapMarkerUserSearchPublic = ({
  className,
  title = '',
  onClick,
  zIndex,
  zIndexHover,
  isPublic,
  user,
  lng,
  lat,
}: Props) => (
  <Marker
    className={styles['map-marker-wrapper']}
    latitude={lat}
    longitude={lng}
    offset={[-28, -61]}
    anchor="top-left"
    style={{ zIndex }}
  >
    <MapMarkerUserSearch
      className={classNames([styles['map-marker'], className])}
      onClick={onClick}
      tabIndex="-1"
      isPublic={isPublic}
      user={user}
      title={title}
      zIndex={zIndex}
      zIndexHover={zIndexHover}
    />
  </Marker>
);

export default MapMarkerUserSearchPublic;
