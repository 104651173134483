/* @flow */
import React from 'react';
import { Marker } from 'react-map-gl';

import InfoWindow from './components/InfoWindow';
import PureMapMarker from './components/PureMapMarker';

import styles from './MapMarker.module.scss';

type PropsMapMarker = {
  zIndex?: number,
  lng?: number,
  lat?: number,
  markerStyle?: string,
  className?: string,
  withMarkerPopUp?: boolean,
  zIndexHover?: ?string | number,
  user: {
    id: ?string,
    first_name: ?string,
    last_name: ?string,
    name?: ?string,
    avatar: ?{
      id: string,
      smallThumbnail?: ?string,
    },
  },
};

const MapMarker = (props: PropsMapMarker) => {
  const { zIndex, lat, lng, withMarkerPopUp = true, ...rest } = props;
  if (!lat || !lng) {
    return (
      <>
        {withMarkerPopUp ? (
          <InfoWindow {...props}>
            <PureMapMarker {...props} />
          </InfoWindow>
        ) : (
          <PureMapMarker {...props} />
        )}
      </>
    );
  }
  return (
    <Marker
      latitude={lat}
      longitude={lng}
      style={{
        zIndex,
      }}
      offset={[-28, -61]}
      className={styles.customMapBoxMarker}
      anchor="top-left"
      {...rest}
    >
      {withMarkerPopUp ? (
        <InfoWindow {...props}>
          <PureMapMarker {...props} />
        </InfoWindow>
      ) : (
        <PureMapMarker {...props} />
      )}
    </Marker>
  );
};

export { PureMapMarker };
export default MapMarker;
