/* @flow */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  libphonenumberHelpers,
  stringManipulation,
} from '@pluralcom/plural-js-utils';

import { errorTexts } from '../../assets/data';

import DialogInputTelephone from '../DialogInputTelephone/DialogInputTelephone';

type DialogInputTelephoneConfirmProps = {
  value: string,
  onSuccess?: (parsed: { value: string, e164: string }) => any,
  className?: ?string,
  dialogProps?: ?Object,
  primaryBtnProps?: ?Object,
};

const defaultDialogProps = {
  primaryBtnText: 'Confirm',
  title: 'Confirm Country Code',
  bodyText: 'Please confirm the country code for this phone number.',
};

const getParsedValues = (dialogProps) => {
  const { value, e164 } = dialogProps || {};
  if (e164) {
    const parsedNum = libphonenumberHelpers.parseNumber(e164, null);
    if (parsedNum.phone) {
      return { value, e164 };
    }
  }
  if (value) {
    const rawNumeric = stringManipulation.replaceNonNumeric(value);
    const valToParse = rawNumeric.startsWith('00')
      ? `+${rawNumeric.substring(2, rawNumeric.length)}`
      : `+${rawNumeric}`;
    const parsedNum = libphonenumberHelpers.parseNumber(valToParse, null);
    const formattedNumber =
      parsedNum.phone && libphonenumberHelpers.formatNumber(parsedNum, 'E.164');
    if (formattedNumber) {
      return { value, e164: formattedNumber };
    }
  }
  return null;
};

const DialogInputTelephoneConfirm = ({
  className,
  dialogProps: inDialogProps = {},
  onSuccess,
  value,
  ...rest
}: DialogInputTelephoneConfirmProps) => {
  const [dialogProps, setDialogProps] = useState({ value: value || '' });
  /** Update dialogProps.value when value changes */
  useEffect(() => {
    setDialogProps((currDialogProps) => ({
      ...currDialogProps,
      value: value || currDialogProps.value || '',
    }));
  }, [value]);
  return (
    <DialogInputTelephone
      className={classNames(className)}
      {...rest}
      {...dialogProps}
      value={value}
      onChange={({ value: changedValue, e164 }) => {
        setDialogProps({
          ...dialogProps,
          error: null,
          value: changedValue,
          e164,
        });
      }}
      dialogProps={{
        ...defaultDialogProps,
        ...inDialogProps,
        toggle: () => {
          setDialogProps({});
          if (inDialogProps && typeof inDialogProps.toggle === 'function') {
            inDialogProps.toggle();
          }
        },
        primaryBtnOnClick: (e) => {
          e.preventDefault();
          const parsedObj = getParsedValues(dialogProps);
          if (!parsedObj) {
            setDialogProps({
              ...dialogProps,
              error: errorTexts.invalidPhoneNumber,
            });
            return;
          }
          if (typeof onSuccess === 'function') {
            onSuccess(parsedObj);
          }
        },
        ...dialogProps.dialogProps,
      }}
    />
  );
};

export default DialogInputTelephoneConfirm;
