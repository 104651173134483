/* @flow */
import React from 'react';
import classNames from 'classnames';

import FancyDialog from '../FancyDialog/FancyDialog';
import Tile from '../Tile/Tile';
import TileInputTelephoneItem from '../TileInputTelephoneItem/TileInputTelephoneItem';

import styles from './DialogInputTelephone.module.scss';

type Props = {
  className?: ?string,
  inputTelephoneProps: ?Object,
  dialogProps?: ?Object,
  tileProps?: ?Object,
};

const DialogInputTelephone = ({
  className,
  tileProps,
  dialogProps,
  ...rest
}: Props) => (
  <FancyDialog
    className={classNames(className)}
    bodyClassName={styles.body}
    primaryBtnClassName={classNames([
      styles.btn,
      {
        [dialogProps && dialogProps.primaryBtnClassName]:
          dialogProps && dialogProps.primaryBtnClassName,
      },
    ])}
    {...dialogProps}
  >
    <Tile
      uiType="clay"
      className={classNames([
        styles.tile,
        {
          [tileProps && tileProps.className]: tileProps && tileProps.className,
        },
      ])}
    >
      <TileInputTelephoneItem {...rest} />
    </Tile>
  </FancyDialog>
);

export default DialogInputTelephone;
