/**
 * @generated SignedSource<<193c4dfbf5faa7ccc7d8b2959d2a69bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type plStripeCheckoutHelpers_checkoutSetupSessionQuery$variables = {
  cancel_url: string;
  success_url: string;
};
export type plStripeCheckoutHelpers_checkoutSetupSessionQuery$data = {
  readonly viewer: {
    readonly paymentMethodsCreation: {
      readonly checkoutSetupSession: {
        readonly error: {
          readonly message: string | null;
        } | null;
        readonly id: string;
        readonly raw_id: string | null;
      } | null;
    } | null;
  } | null;
};
export type plStripeCheckoutHelpers_checkoutSetupSessionQuery = {
  response: plStripeCheckoutHelpers_checkoutSetupSessionQuery$data;
  variables: plStripeCheckoutHelpers_checkoutSetupSessionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cancel_url"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "success_url"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cancel_url",
    "variableName": "cancel_url"
  },
  {
    "kind": "Variable",
    "name": "success_url",
    "variableName": "success_url"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "raw_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "plStripeCheckoutHelpers_checkoutSetupSessionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentMethodsCreation",
            "kind": "LinkedField",
            "name": "paymentMethodsCreation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "StripeCheckoutSession",
                "kind": "LinkedField",
                "name": "checkoutSetupSession",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Error",
                    "kind": "LinkedField",
                    "name": "error",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "plStripeCheckoutHelpers_checkoutSetupSessionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentMethodsCreation",
            "kind": "LinkedField",
            "name": "paymentMethodsCreation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "StripeCheckoutSession",
                "kind": "LinkedField",
                "name": "checkoutSetupSession",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Error",
                    "kind": "LinkedField",
                    "name": "error",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cd2db674330f82fc6f25469b9b3b6762",
    "id": null,
    "metadata": {},
    "name": "plStripeCheckoutHelpers_checkoutSetupSessionQuery",
    "operationKind": "query",
    "text": "query plStripeCheckoutHelpers_checkoutSetupSessionQuery(\n  $success_url: String!\n  $cancel_url: String!\n) {\n  viewer {\n    paymentMethodsCreation {\n      checkoutSetupSession(success_url: $success_url, cancel_url: $cancel_url) {\n        id\n        raw_id\n        error {\n          message\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b1760e27f2c75f8fc235997bd0ae700b";

export default node;
