import React from 'react';
import type { Environment } from 'react-relay';
import { PLURALBOT_USER_ID } from '@pluralcom/plural-js-utils';
import {
  ButtonFlat,
  Icon,
  MenuItem,
  PopOverMenu,
} from '@pluralcom/blueprint';
import { PopoverRefHandle } from '@pluralcom/blueprint/src/react/molecules/PopOver';
import classNames from 'classnames';

import { MarkAsReadMutation } from '../../graphql/mutations';

import styles from './ActivityOptionsTooltip.module.scss';
import { MessengerListItem_data$data } from '../../containers/Messenger/components/MessengerList/components/MessengerListItem/__generated__/MessengerListItem_data.graphql';

interface Props {
  /** Relay environment */
  environment: Environment;
  /** Activity */
  acivity?: MessengerListItem_data$data;
  /** Function to handle visible change */
  onVisibleChange: Function;
  /** Function to handle something'swrong click */
  onSomethingWrongClick: Function;
  /** custom className */
  className?: string;
  /** Function to handle archive click */
  onArchiveClick: Function;
  /** button click callback */
  buttonProps?: React.ComponentProps<typeof ButtonFlat>;
  /** class name for button */
  buttonClassName?: string;
  /** ref */
  ref?: React.RefObject<PopoverRefHandle>;
}

const ActivityOptionsTooltip = ({
  environment = {},
  acivity,
  onVisibleChange,
  onSomethingWrongClick,
  onArchiveClick,
  className,
  buttonClassName,
  buttonProps,
  ref,
  ...rest
}: Props) => {
  const { id, seen_at, archived_at, user = { id } } = acivity || {};
  const isPluralBot = user && PLURALBOT_USER_ID === user && user?.id;

  const onMarkAsRead = (e) => {
    e.stopPropagation();
    e.preventDefault();
    MarkAsReadMutation(environment)(id, !seen_at, !!archived_at);
  };

  const onMarkAsWrong = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onSomethingWrongClick();
  };

  const onArchiveOptionClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onArchiveClick();
  };

  const _items: Array<React.ComponentProps<typeof MenuItem>> = [
    {
      children: `Mark as ${seen_at ? 'Unread' : 'Read'}`,
      onClick: (e) => onMarkAsRead(e),
      leftIconProps: {
        icon: ['fas', seen_at ? 'envelope-open' : 'envelope'],
        type: 'fa',
      },
      className: styles['dropdown-item'],
      textClassName: styles['text-container'],
    },
    ...(!isPluralBot
      ? [
          {
            children: 'Something’s Wrong',
            onClick: (e) => onMarkAsWrong(e),
            leftIconProps: {
              icon: ['fas', 'flag'] as React.ComponentProps<
                typeof Icon
              >['icon'],
              type: 'fa' as React.ComponentProps<typeof Icon>['type'],
            },
            className: styles['dropdown-item'],
            textClassName: styles['text-container'],
          },
        ]
      : []),
    {
      children: 'Archive',
      onClick: (e) => onArchiveOptionClick(e),
      leftIconProps: {
        icon: ['fas', 'trash-can'],
        type: 'fa',
      },
      className: styles['dropdown-item'],
      textClassName: styles['text-container'],
    },
  ];

  return (
    <PopOverMenu
      ref={ref}
      isDivider={false}
      className={classNames([
        styles['dropdown-container'],
        styles.container,
        className,
      ])}
      items={_items}
      size="xs"
      placement="bottom"
      triggerClassName={styles['trigger-container']}
      tooltipClassName={styles['tooltip-container']}
      renderTrigger={
        <ButtonFlat
          uiType="ghost"
          size="sm"
          borderType
          iconProps={{
            icon: ['fas', 'ellipsis'],
            className: styles['trigger-icon'],
            type: 'fa',
          }}
          {...buttonProps}
          className={classNames([styles['button-container'], buttonClassName])}
          onClick={(e) => {
            e?.stopPropagation();
            e?.preventDefault();
          }}
        />
      }
      {...rest}
    />
  );
};

export default ActivityOptionsTooltip;
