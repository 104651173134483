/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation AddPayoutMethodMutation($input: AddPayoutInput!) {
    addPayout(input: $input) {
      error {
        message
      }
      payoutMethods {
        id
        bank_account {
          id
          account_holder_name
          routing_number
          last4
        }
      }
      user {
        id
        settings {
          id
          has_valid_payout_method
        }
      }
    }
  }
`;

export default ({ payout_id }: { payout_id: string }): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        payout_id,
      },
    };

    const configs = [
      {
        type: 'RANGE_ADD',
        parentID: 'client:root:viewer',
        edgeName: 'payoutMethods',
      },
    ];

    commitMutation({
      mutation,
      variables,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });
