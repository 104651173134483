/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation UpdateSellerAccountMutation($input: UpdateSellerAccountInput!) {
    updateSellerAccount(input: $input) {
      error {
        message
      }
      payoutMethods {
        id
        # @gql-reused Used in [PayoutMethods, UpdateSellerAccountMutation, sellerInformationSubscribe] Ensure you update -- @todo make fragment and reuse
        seller_information {
          id
          first_name
          last_name
          line1
          line2
          postal_code
          city
          state
          country
          ssn_last_4_provided
          dob
          # gender
          id_number_provided
          requirements {
            id
            current_deadline
            currently_due
            disabled_reason
            disabled_reason_text
            errors {
              code
              reason
              requirement
            }
            eventually_due
            past_due
            pending_verification
          }
          verification {
            id
            status
            document {
              id
              details
              front
              back
            }
            additional_document {
              id
              details
              front
              back
            }
          }
        }
      }
      user {
        id
        settings {
          id
          has_valid_payout_method
        }
      }
    }
  }
`;

export default ({
  stripe_account_token,
}: {
  stripe_account_token: string,
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        stripe_account_token,
      },
    };

    commitMutation({
      mutation,
      variables,
      onCompleted: resolve,
      onError: reject,
    });
  });
