/**
 * @generated SignedSource<<24d640b6d00f31ad14a6673b8177c290>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GenderType = "FEMALE" | "MALE" | "NOT_SPECIFIED" | "%future added value";
export type VerifyPinInput = {
  auth_reqid: string;
  clientMutationId?: string | null;
  email?: string | null;
  phone?: string | null;
  pin: string;
  with_auth?: boolean | null;
};
export type VerifyPinMutation$variables = {
  input: VerifyPinInput;
};
export type VerifyPinMutation$data = {
  readonly verifyPin: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly intercom_hash: string | null;
    readonly profile: {
      readonly avatar: {
        readonly id: string;
        readonly smallThumbnail: string | null;
        readonly thumbnail: string | null;
      } | null;
      readonly birthdate: string | null;
      readonly created_at: any;
      readonly email: string | null;
      readonly emails: ReadonlyArray<{
        readonly id: string;
        readonly value: string | null;
      } | null> | null;
      readonly first_name: string | null;
      readonly gender: GenderType | null;
      readonly id: string;
      readonly last_name: string | null;
      readonly last_network_synced_timestamp: any | null;
      readonly name: string | null;
      readonly phone: string | null;
      readonly phones: ReadonlyArray<{
        readonly id: string;
        readonly value: string | null;
      } | null> | null;
      readonly profile_color: string | null;
      readonly published_at: any | null;
      readonly username: string | null;
      readonly website: string | null;
    } | null;
    readonly roles: ReadonlyArray<string | null> | null;
  } | null;
};
export type VerifyPinMutation = {
  response: VerifyPinMutation$data;
  variables: VerifyPinMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profile_color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "smallThumbnail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "published_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Email",
      "kind": "LinkedField",
      "name": "emails",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Phone",
      "kind": "LinkedField",
      "name": "phones",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_network_synced_timestamp",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roles",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "intercom_hash",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyPinMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyPinPayload",
        "kind": "LinkedField",
        "name": "verifyPin",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyPinMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyPinPayload",
        "kind": "LinkedField",
        "name": "verifyPin",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "72ca55368451b4d6c971754a27d6ddad",
    "id": null,
    "metadata": {},
    "name": "VerifyPinMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyPinMutation(\n  $input: VerifyPinInput!\n) {\n  verifyPin(input: $input) {\n    profile {\n      id\n      username\n      name\n      first_name\n      last_name\n      profile_color\n      avatar {\n        id\n        smallThumbnail\n        thumbnail\n      }\n      email\n      phone\n      website\n      gender\n      birthdate\n      created_at\n      published_at\n      emails {\n        id\n        value\n      }\n      phones {\n        id\n        value\n      }\n      last_network_synced_timestamp\n    }\n    roles\n    intercom_hash\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "81a48a11e4ab3c2505b816986b7684de";

export default node;
