/* @flow */
import React from 'react';
import classNames from 'classnames';
import { TouchableOpacity } from '@pluralcom/blueprint';

import ModalMediaBase from '../ModalMediaBase/ModalMediaBase';
import Picture from '../Picture/Picture';
import styles from './ModalOnBoarding.module.scss';

// @todo rename this component to ModalFullPageServerData as in native

type ButtonsProps = {
  text: string,
  onClick?: Function,
};
type Props = {
  title?: string,
  text?: string | Function,
  imgUrl?: string,
  imgUrlLg?: string,
  imgUrlXlg?: string,
  primaryButton?: ButtonsProps,
  secondaryButton?: ButtonsProps,
  className?: string,
  titleClassName?: string,
};

const ModalOnBoarding = ({
  title,
  text,
  imgUrl,
  imgUrlLg,
  imgUrlXlg,
  primaryButton = {},
  secondaryButton,
  className,
  titleClassName,
  ...rest
}: Props) => (
  <ModalMediaBase
    className={classNames(styles['modal-media-base-container'], className)}
    LeftComponent={() => (
      <Picture
        className={styles.image}
        src={imgUrl}
        srcLg={imgUrlLg}
        srcXlg={imgUrlXlg}
        alt="ModalOnBoarding"
      />
    )}
    RightComponent={() => (
      <div className={styles.container}>
        <h2 className={classNames(styles.title, titleClassName)}>{title}</h2>
        <p className={styles.text}>
          {typeof text === 'function' ? text() : text}
        </p>
        <TouchableOpacity
          {...primaryButton}
          className={classNames(styles.button, styles['primary-button'])}
        >
          {primaryButton.text}
        </TouchableOpacity>
        {secondaryButton && (
          <TouchableOpacity
            {...secondaryButton}
            className={classNames(styles.button, styles['secondary-button'])}
          >
            {secondaryButton.text}
          </TouchableOpacity>
        )}
      </div>
    )}
    {...rest}
  />
);

export default ModalOnBoarding;
