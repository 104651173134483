/* @flow */
import React from 'react';
import classNames from 'classnames';
import InputTelephone from '../InputTelephone/InputTelephone';
import TileItem from '../TileItem/TileItem';

import inputStyles from '../TileInputItem/TileInputItem.module.scss';
import styles from './TileInputTelephoneItem.module.scss';

type Props = {
  className: ?string,
  error: ?any,
  errorClassName: ?string,
  tileItemProps: Object,
};
const TileInputTelephoneItem = ({
  className,
  errorClassName,
  error,
  tileItemProps,
  ...rest
}: Props) => (
  <TileItem noWrapper error={error} {...tileItemProps}>
    <InputTelephone
      separateDialCode
      className={classNames([inputStyles.item, styles.input, className])}
      {...rest}
    />
  </TileItem>
);

export default TileInputTelephoneItem;
