/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {
  generateOptimisticId,
  objectHelpers,
} from '@pluralcom/plural-js-utils';
import { commitMutation } from '../..';
import type { LocationType } from '../../../../flow';

const CreateSkillOfferMutation = (
  {
    title,
    description,
    price,
    service_location_type,
    date_setting,
    service_duration,
    service_delivery_duration,
    hiring_type,
    service_location,
  }: {
    skillId: string,
    title: string,
    description: string,
    price: number,
    service_location_type: Array<'buyer', 'seller', 'remote', 'online'>,
    hiring_type: string,
    date_setting: Array<'set_on_create', 'seller_timeframe'>,
    service_duration: number,
    service_delivery_duration: number,
    service_location: LocationType,
  },
  skillId: string,
  skill,
): Promise =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation CreateSkillOfferMutation($input: CreateSkillOfferInput!) {
        createSkillOffer(input: $input) {
          error {
            message
          }
          skill_offer_edge {
            node {
              id
              title
              description
              price
              service_location_type
              service_location {
                id
                latitude
                longitude
                name
                formatted_address
              }
              date_setting
              service_duration
              service_delivery_duration
              hiring_type
              skill {
                id
                hiring_types
              }
            }
          }
        }
      }
    `;

    const service_locationVariable = service_location_type?.includes('seller')
      ? pick(service_location, ['longitude', 'latitude'])
      : undefined;
    const variables = {
      input: objectHelpers.omitUndefined({
        skill_id: skillId,
        title,
        description,
        price: price ? parseFloat(price) : undefined,
        date_setting,
        service_duration,
        service_delivery_duration,
        service_location_type,
        service_location:
          service_locationVariable?.latitude &&
          service_locationVariable.longitude
            ? service_locationVariable
            : undefined,
        hiring_type,
      }),
    };

    const optimisticResponse = {
      createSkillOffer: {
        error: null,
        skill_offer_edge: {
          node: {
            id: generateOptimisticId('SkillOffer'),
            title: title || null,
            description: description || null,
            price: price ? parseFloat(price) : null,
            // @todo - remove when UI supports multiple date settings
            date_setting: date_setting || null,
            service_duration: service_duration || null,
            service_delivery_duration: service_delivery_duration || null,
            service_location_type: service_location_type || null,
            service_location:
              service_location?.latitude && service_location.longitude
                ? {
                    ...service_location,
                    id: generateOptimisticId('SkillOffer_service_location'),
                  }
                : null,
            hiring_type,
            skill: {
              id: skillId,
              hiring_types: [...(skill?.hiring_types || []), hiring_type],
            },
          },
        },
      },
    };

    const configs = [
      {
        type: 'RANGE_ADD',
        parentID: skillId,
        connectionInfo: [
          {
            key: 'OffersList_offers',
            rangeBehavior: 'prepend',
          },
          {
            key: 'PricingSection_SkillCard_offers',
            rangeBehavior: 'prepend',
          },
        ],
        edgeName: 'skill_offer_edge',
      },
    ];

    return commitMutation({
      mutation,
      optimisticResponse,
      variables,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default CreateSkillOfferMutation;
