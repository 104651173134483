/* @flow */
import { useEffect } from 'react';

import startsWithAnyOf from '../../../../utils/startsWithAnyOf';

// @todo find a better way
const disabledOnRoutes = ['/search'];

const useScrollToTop = ({ location, smooth }) => {
  /** Scroll to top on route change */
  useEffect(() => {
    if (!startsWithAnyOf(location.pathname, disabledOnRoutes)) {
      window.scrollTo({
        behavior: smooth ? 'smooth' : 'auto',
        left: 0,
        top: 0,
      });
    }
  }, [location.pathname, smooth]);
};

export default useScrollToTop;
