/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

import type { CompleteCheckoutSessionMutationResponse } from './__generated__/CompleteCheckoutSessionMutation.graphql';

const mutation = graphql`
  mutation CompleteCheckoutSessionMutation(
    $input: CompleteCheckoutSessionInput!
  ) {
    completeCheckoutSession(input: $input) {
      success
      error {
        message
      }
    }
  }
`;

const completeCheckoutSession = ({
  checkout_session_id,
}: {
  checkout_session_id: string,
}): Promise<CompleteCheckoutSessionMutationResponse> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        checkout_session_id,
      },
    };

    commitMutation({
      mutation,
      variables,

      onCompleted: resolve,
      onError: reject,
    });
  });

export default completeCheckoutSession;
