/**
 * @generated SignedSource<<94ab62daede4726350c4227890611a56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendMessageInput = {
  clientMutationId?: string | null;
  files?: ReadonlyArray<any | null> | null;
  location?: LocationInput | null;
  message?: string | null;
  optimistic_id?: string | null;
  thread_id: string;
};
export type LocationInput = {
  city?: string | null;
  country?: string | null;
  country_code?: string | null;
  is_anonymized?: boolean | null;
  latitude?: number | null;
  longitude?: number | null;
  offset?: string | null;
  postal_code?: string | null;
  zoom_level?: number | null;
};
export type SendMessageMutation$variables = {
  input: SendMessageInput;
};
export type SendMessageMutation$data = {
  readonly sendMessage: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly newMessageEdge: {
      readonly node: {
        readonly created_at: any;
        readonly file: {
          readonly id: string;
          readonly image: {
            readonly id: string;
            readonly medium: string | null;
            readonly original: string | null;
            readonly original_optimized: string | null;
          } | null;
          readonly mime_type: string | null;
          readonly original_name: string | null;
          readonly size: number | null;
          readonly url: string;
        } | null;
        readonly from: {
          readonly id: string;
        } | null;
        readonly id: string;
        readonly is_subscription: boolean | null;
        readonly location: {
          readonly city: string | null;
          readonly id: string;
          readonly latitude: number;
          readonly longitude: number;
          readonly postal_code: string | null;
          readonly street_name: string | null;
          readonly street_number: string | null;
        } | null;
        readonly message: string | null;
        readonly optimistic_id: string | null;
        readonly updated_at: any;
      } | null;
    } | null;
  } | null;
};
export type SendMessageMutation = {
  response: SendMessageMutation$data;
  variables: SendMessageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "MessageEdge",
  "kind": "LinkedField",
  "name": "newMessageEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Message",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "from",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "optimistic_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_subscription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "file",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "original_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mime_type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "size",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "image",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "medium",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "original",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "original_optimized",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Location",
          "kind": "LinkedField",
          "name": "location",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latitude",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "longitude",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "street_number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "street_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postal_code",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updated_at",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendMessageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendMessagePayload",
        "kind": "LinkedField",
        "name": "sendMessage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendMessageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendMessagePayload",
        "kind": "LinkedField",
        "name": "sendMessage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4384fc07ed37776558b7c5f6f4d4435",
    "id": null,
    "metadata": {},
    "name": "SendMessageMutation",
    "operationKind": "mutation",
    "text": "mutation SendMessageMutation(\n  $input: SendMessageInput!\n) {\n  sendMessage(input: $input) {\n    error {\n      message\n      id\n    }\n    newMessageEdge {\n      node {\n        id\n        message\n        from {\n          id\n        }\n        optimistic_id\n        is_subscription\n        file {\n          id\n          url\n          original_name\n          mime_type\n          size\n          image {\n            id\n            medium\n            original\n            original_optimized\n          }\n        }\n        location {\n          id\n          latitude\n          longitude\n          street_number\n          street_name\n          city\n          postal_code\n        }\n        created_at\n        updated_at\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "124b16cfd497671af6a47956e5fefc17";

export default node;
