/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';
import type { Environment } from 'react-relay';

const ArchiveActivityMutation = (environment: Environment) => (
  id: string,
  value = true,
  { searchTerm }: { searchTerm?: string } = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation ArchiveActivityMutation($input: EditActivityInput!) {
        editActivity(input: $input) {
          activityID
          activity {
            id
            archived_at
          }
        }
      }
    `;

    const variables = {
      input: {
        id,
        archived: value,
      },
    };
    const configs = [
      {
        type: 'RANGE_DELETE',
        parentID: 'client:root:viewer',
        connectionKeys: [
          {
            key: 'YouNotificationsList_activities',
          },
          {
            key: 'YouNotificationsList_activities',
            filters: { searchTerm },
          },
          {
            key: 'Messenger_activities',
          },
          {
            key: 'Messenger_activities',
            filters: { searchTerm },
          },
        ],
        pathToConnection: ['viewer', 'activities'],
        deletedIDFieldName: 'activityID',
      },
    ];

    const optimisticResponse = {
      editActivity: {
        activityID: id,
        activity: {
          id,
          archived_at: new Date().toISOString(),
        },
      },
    };

    commitMutation(environment, {
      mutation,
      optimisticResponse,
      variables,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default ArchiveActivityMutation;
