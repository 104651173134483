/* @flow */
import React, { Component } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import classNames from 'classnames';
import { libphonenumberHelpers } from '@pluralcom/plural-js-utils';
import isNaN from 'lodash/isNaN';
import 'react-intl-tel-input/dist/main.css';

import styles from './InputTelephone.module.scss';

type Props = {
  className?: string,
  disableNonPhoneChars?: boolean,
  name?: string,
  fieldName?: string,
  fieldId?: string,
  value?: string,
  defaultValue?: string,
  countriesData: Array<Array<Object>>,
  allowDropdown?: boolean,
  autoHideDialCode?: boolean,
  autoPlaceholder?: boolean,
  customPlaceholder: Function,
  excludeCountries?: Array<string>,
  formatOnInit?: boolean,
  separateDialCode?: boolean,
  defaultCountry?: string,
  geoIpLookup: Function,
  nationalMode?: boolean,
  numberType?: string,
  noCountryDataHandler: Function,
  onlyCountries?: Array<string>,
  preferredCountries?: Array<string>,
  utilsScript?: string,
  onPhoneNumberChange?: Function,
  onPhoneNumberBlur?: Function,
  onSelectFlag?: Function,
  disabled?: boolean,
  placeholder?: string,
  autoFocus?: boolean,
  autoComplete?: string,
  style?: Object,
  useMobileFullscreenDropdown?: boolean,
  telInputProps?: Object,
  format?: boolean,
  // Additional Custom
  onChange?: ?({
    status: boolean,
    value: string,
    countryData: Object,
    number: string,
    e164?: ?string,
  }) => any,
};

type State = {
  value: string,
};

class InputTelephone extends Component<*, Props, State> {
  /**
   * returns true if the string starts with any of the strings in the array
   */
  static startsWithAnyOf(string: string, array: Array<string>) {
    for (let i = 0; i < array.length; i += 1) {
      if (string.startsWith(array[i])) {
        return true;
      }
    }
    return false;
  }

  /**
   * returns true if the given char deeply equals any of the chars in the array
   */
  static charEqualsAnyOf(char: string, array: Array<string>) {
    for (let i = 0; i < array.length; i += 1) {
      if (char === array[i]) {
        return true;
      }
    }
    return false;
  }

  /**
   * if a non phone character is typed, trim it
   */
  static trimNonPhoneNumberChar(value: string): string {
    const valueLastChar = value.charAt(value.length - 1);
    if (
      (isNaN(parseInt(valueLastChar, 10)) &&
        !InputTelephone.charEqualsAnyOf(valueLastChar, ['-', '+', ' '])) ||
      value.endsWith('  ') ||
      InputTelephone.startsWithAnyOf(value, [' ', '-', '++'])
    ) {
      return value.substring(0, value.length - 1);
    }
    return value;
  }

  _inputRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = { value: props.value || '' };
  }

  componentDidMount = () => {
    const { autoFocus } = this.props;
    setTimeout(() => {
      if (autoFocus && this._inputRef && this._inputRef.current) {
        this._inputRef.current.tel.focus();
      }
    }, 0);
  };

  _handleChange = ({ status, value, countryData, number, e164 }) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange({
        status,
        value,
        countryData,
        number,
        e164,
      });
    }
  };

  _handlePhoneNumberChange = (
    inStatus: boolean,
    value: string,
    countryData: Object,
    inNumber: string,
    id: string,
  ): void => {
    let status = inStatus;
    let number = inNumber;
    // Handle bug where number is called with dual country code
    if (number.split('+').length === 3) {
      number = `+${number.split('+')[2]}`;
      try {
        const parsed = libphonenumberHelpers.parseNumber(number);
        if (parsed && parsed.phone) {
          status = true;
        }
      } catch (err) {
        // handled
      }
    }
    const synthValue = this._synthesizeValue(value);
    this.setState({ value: synthValue });
    let e164;
    if (status) {
      e164 = `+${number.replace(/\D/g, '')}`;
    }
    if (this.props.onPhoneNumberChange) {
      this.props.onPhoneNumberChange(
        status,
        synthValue,
        countryData,
        number,
        id,
        { e164 },
      );
    }
    this._handleChange({
      status,
      value: synthValue,
      countryData,
      number,
      e164,
    });
  };

  _handleSelectFlag = (synthValue, countryData, number, status): void => {
    this.setState({ value: synthValue });
    let e164;
    if (status) {
      e164 = `+${number.replace(/\D/g, '')}`;
    }
    let newStatus;
    let newNumber;
    if (countryData && number === undefined && status === undefined) {
      let parsedPhone = {};
      try {
        parsedPhone = libphonenumberHelpers.parseNumber(
          synthValue.replace(/\D/g, ''),
          countryData.iso2.toUpperCase(),
        );
      } catch (err) {
        // handled
      }
      newStatus = false;
      newNumber = synthValue;
      if (parsedPhone.phone) {
        newStatus = true;
        newNumber = libphonenumberHelpers.formatNumber(parsedPhone, 'E.164');
        e164 = newNumber;
      }
    }
    if (this.props.onSelectFlag) {
      this.props.onSelectFlag(
        synthValue,
        countryData,
        newNumber || number,
        newStatus || status || false,
        { e164 },
      );
    }
    this._handleChange({
      status: newStatus || status || false,
      value: synthValue,
      countryData,
      number: newNumber || number,
      e164,
    });
  };

  _handlePhoneNumberBlur = (
    status: string,
    value: string,
    countryData: Object,
    number: string,
    id: string,
  ): void => {
    const synthValue = this._synthesizeValue(value);
    this.setState({ value: synthValue });
    if (this.props.onPhoneNumberBlur) {
      this.props.onPhoneNumberBlur(status, synthValue, countryData, number, id);
    }
  };

  _synthesizeValue = (value: string) => {
    if (this.props.disableNonPhoneChars) {
      return InputTelephone.trimNonPhoneNumberChar(value);
    }
    return value;
  };

  render() {
    const {
      className,
      name,
      telInputProps,
      // filter
      onChange,
      ...rest
    } = this.props;
    return (
      <IntlTelInput
        {...rest}
        value={this.state.value}
        containerClassName={classNames(
          'intl-tel-input',
          styles.container,
          className,
        )}
        fieldName={name}
        onPhoneNumberChange={this._handlePhoneNumberChange}
        onSelectFlag={this._handleSelectFlag}
        onPhoneNumberBlur={this._handlePhoneNumberBlur}
        utilsScript="libphonenumber.js"
        ref={this._inputRef}
        telInputProps={{
          maxLength: '25',
          ...telInputProps,
        }}
      />
    );
  }
}

InputTelephone.defaultProps = {
  defaultCountry: 'us',
  preferredCountries: ['us', 'gb', 'au'],
  format: true,
  telInputProps: {},
  disableNonPhoneChars: true,
};

/**
 * Function Docs
 *
 * onPhoneNumberChange(status, value, countryData, number, id)
 * onPhoneNumberBlur(status, value, countryData, number, id)
 */

export default InputTelephone;
