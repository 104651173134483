/* @flow */
let loadStripeBase = null;

(async () => {
  if (window.navigator.userAgent !== 'ReactSnap') {
    const stripe = await import('@stripe/stripe-js');
    loadStripeBase = stripe.loadStripe;
  }
})();

const loadStripe = (...args) => {
  if (window.navigator.userAgent === 'ReactSnap') {
    return undefined;
  }
  return loadStripeBase(process.env.REACT_APP_STRIPE_PUBLIC_KEY, ...args);
};

export { loadStripe };
