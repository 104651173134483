/**
 * @generated SignedSource<<6fc09f1bf69773dbfd4a524e31fc7941>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type StripePayoutCountryEnumType = "AR" | "AT" | "AU" | "BE" | "BG" | "CA" | "CY" | "CZ" | "DE" | "DK" | "EE" | "EG" | "ES" | "FI" | "FR" | "GR" | "HR" | "HU" | "IE" | "IT" | "LT" | "LU" | "LV" | "MT" | "MX" | "NL" | "NZ" | "PL" | "PT" | "RO" | "SA" | "SE" | "SI" | "SK" | "US" | "%future added value";
export type sellerInformationSubscribeSubscription$variables = {};
export type sellerInformationSubscribeSubscription$data = {
  readonly sellerInformationSubscribe: {
    readonly city: string | null;
    readonly country: StripePayoutCountryEnumType | null;
    readonly dob: string | null;
    readonly first_name: string | null;
    readonly id: string;
    readonly id_number_provided: boolean | null;
    readonly last_name: string | null;
    readonly line1: string | null;
    readonly line2: string | null;
    readonly postal_code: string | null;
    readonly requirements: {
      readonly current_deadline: any | null;
      readonly currently_due: ReadonlyArray<string | null> | null;
      readonly disabled_reason: string | null;
      readonly disabled_reason_text: string | null;
      readonly errors: ReadonlyArray<{
        readonly code: string | null;
        readonly reason: string | null;
        readonly requirement: string | null;
      } | null> | null;
      readonly eventually_due: ReadonlyArray<string | null> | null;
      readonly id: string;
      readonly past_due: ReadonlyArray<string | null> | null;
      readonly pending_verification: ReadonlyArray<string | null> | null;
    } | null;
    readonly ssn_last_4_provided: boolean | null;
    readonly state: string | null;
    readonly verification: {
      readonly additional_document: {
        readonly back: string | null;
        readonly details: string | null;
        readonly front: string | null;
        readonly id: string;
      } | null;
      readonly document: {
        readonly back: string | null;
        readonly details: string | null;
        readonly front: string | null;
        readonly id: string;
      } | null;
      readonly id: string;
      readonly status: string | null;
    } | null;
  } | null;
};
export type sellerInformationSubscribeSubscription = {
  response: sellerInformationSubscribeSubscription$data;
  variables: sellerInformationSubscribeSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "details",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "front",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "back",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SellerInformation",
    "kind": "LinkedField",
    "name": "sellerInformationSubscribe",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "first_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "last_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "line1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "line2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postal_code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "city",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "country",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ssn_last_4_provided",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dob",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id_number_provided",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "StripeAccountRequirements",
        "kind": "LinkedField",
        "name": "requirements",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "current_deadline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currently_due",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "disabled_reason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "disabled_reason_text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StripeAccountRequirementsError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reason",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requirement",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "eventually_due",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "past_due",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pending_verification",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "StripeAccountVerification",
        "kind": "LinkedField",
        "name": "verification",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StripeIDDocument",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StripeIDDocument",
            "kind": "LinkedField",
            "name": "additional_document",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "sellerInformationSubscribeSubscription",
    "selections": (v2/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "sellerInformationSubscribeSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4dd55639d989b2ec4421a770cda4e12b",
    "id": null,
    "metadata": {},
    "name": "sellerInformationSubscribeSubscription",
    "operationKind": "subscription",
    "text": "subscription sellerInformationSubscribeSubscription {\n  sellerInformationSubscribe {\n    id\n    first_name\n    last_name\n    line1\n    line2\n    postal_code\n    city\n    state\n    country\n    ssn_last_4_provided\n    dob\n    id_number_provided\n    requirements {\n      id\n      current_deadline\n      currently_due\n      disabled_reason\n      disabled_reason_text\n      errors {\n        code\n        reason\n        requirement\n      }\n      eventually_due\n      past_due\n      pending_verification\n    }\n    verification {\n      id\n      status\n      document {\n        id\n        details\n        front\n        back\n      }\n      additional_document {\n        id\n        details\n        front\n        back\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2dffb31afbd54281a02a45a60bedd6ff";

export default node;
