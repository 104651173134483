/**
 * @generated SignedSource<<ad9ab73fe5f5ab8b160c0c11322de0ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StripeBusinessTypeEnumType = "company" | "individual" | "%future added value";
export type StripePayoutCountryEnumType = "AR" | "AT" | "AU" | "BE" | "BG" | "CA" | "CY" | "CZ" | "DE" | "DK" | "EE" | "EG" | "ES" | "FI" | "FR" | "GR" | "HR" | "HU" | "IE" | "IT" | "LT" | "LU" | "LV" | "MT" | "MX" | "NL" | "NZ" | "PL" | "PT" | "RO" | "SA" | "SE" | "SI" | "SK" | "US" | "%future added value";
export type UpdateSellerAccountInput = {
  accept_tos?: boolean | null;
  business_type?: StripeBusinessTypeEnumType | null;
  clientMutationId?: string | null;
  country?: StripePayoutCountryEnumType | null;
  stripe_account_id?: string | null;
  stripe_account_token?: string | null;
  stripe_person_id?: string | null;
  stripe_person_token?: string | null;
};
export type UpdateSellerAccountMutation$variables = {
  input: UpdateSellerAccountInput;
};
export type UpdateSellerAccountMutation$data = {
  readonly updateSellerAccount: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly payoutMethods: {
      readonly id: string;
      readonly seller_information: {
        readonly city: string | null;
        readonly country: StripePayoutCountryEnumType | null;
        readonly dob: string | null;
        readonly first_name: string | null;
        readonly id: string;
        readonly id_number_provided: boolean | null;
        readonly last_name: string | null;
        readonly line1: string | null;
        readonly line2: string | null;
        readonly postal_code: string | null;
        readonly requirements: {
          readonly current_deadline: any | null;
          readonly currently_due: ReadonlyArray<string | null> | null;
          readonly disabled_reason: string | null;
          readonly disabled_reason_text: string | null;
          readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly reason: string | null;
            readonly requirement: string | null;
          } | null> | null;
          readonly eventually_due: ReadonlyArray<string | null> | null;
          readonly id: string;
          readonly past_due: ReadonlyArray<string | null> | null;
          readonly pending_verification: ReadonlyArray<string | null> | null;
        } | null;
        readonly ssn_last_4_provided: boolean | null;
        readonly state: string | null;
        readonly verification: {
          readonly additional_document: {
            readonly back: string | null;
            readonly details: string | null;
            readonly front: string | null;
            readonly id: string;
          } | null;
          readonly document: {
            readonly back: string | null;
            readonly details: string | null;
            readonly front: string | null;
            readonly id: string;
          } | null;
          readonly id: string;
          readonly status: string | null;
        } | null;
      } | null;
    } | null;
    readonly user: {
      readonly id: string;
      readonly settings: {
        readonly has_valid_payout_method: boolean | null;
        readonly id: string;
      } | null;
    } | null;
  } | null;
};
export type UpdateSellerAccountMutation = {
  response: UpdateSellerAccountMutation$data;
  variables: UpdateSellerAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "details",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "front",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "back",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PayoutMethods",
  "kind": "LinkedField",
  "name": "payoutMethods",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerInformation",
      "kind": "LinkedField",
      "name": "seller_information",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "first_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "line1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "line2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postal_code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ssn_last_4_provided",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dob",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id_number_provided",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StripeAccountRequirements",
          "kind": "LinkedField",
          "name": "requirements",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "current_deadline",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currently_due",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "disabled_reason",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "disabled_reason_text",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StripeAccountRequirementsError",
              "kind": "LinkedField",
              "name": "errors",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reason",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "requirement",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "eventually_due",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "past_due",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pending_verification",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StripeAccountVerification",
          "kind": "LinkedField",
          "name": "verification",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StripeIDDocument",
              "kind": "LinkedField",
              "name": "document",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StripeIDDocument",
              "kind": "LinkedField",
              "name": "additional_document",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Settings",
      "kind": "LinkedField",
      "name": "settings",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "has_valid_payout_method",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSellerAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSellerAccountPayload",
        "kind": "LinkedField",
        "name": "updateSellerAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSellerAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSellerAccountPayload",
        "kind": "LinkedField",
        "name": "updateSellerAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5e5022743f2db5afefbb8fbd45b823fc",
    "id": null,
    "metadata": {},
    "name": "UpdateSellerAccountMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSellerAccountMutation(\n  $input: UpdateSellerAccountInput!\n) {\n  updateSellerAccount(input: $input) {\n    error {\n      message\n      id\n    }\n    payoutMethods {\n      id\n      seller_information {\n        id\n        first_name\n        last_name\n        line1\n        line2\n        postal_code\n        city\n        state\n        country\n        ssn_last_4_provided\n        dob\n        id_number_provided\n        requirements {\n          id\n          current_deadline\n          currently_due\n          disabled_reason\n          disabled_reason_text\n          errors {\n            code\n            reason\n            requirement\n          }\n          eventually_due\n          past_due\n          pending_verification\n        }\n        verification {\n          id\n          status\n          document {\n            id\n            details\n            front\n            back\n          }\n          additional_document {\n            id\n            details\n            front\n            back\n          }\n        }\n      }\n    }\n    user {\n      id\n      settings {\n        id\n        has_valid_payout_method\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3842b4ddd9c067c1a30c99f670d7fc3d";

export default node;
