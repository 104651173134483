/* @flow */
import React from 'react';
import classNames from 'classnames';
import { Anchor, Button, MapMarker } from '@pluralcom/blueprint';

import styles from '../MapMarker.module.scss';

type Props = {
  className: ?string,
  onClick: ?Function,
  to:
    | ?string
    | {
        pathname: ?string,
        search: ?string,
      },
  href: ?string,
  zIndex: ?string | number,
  zIndexHover: ?string | Number,
  target: ?string,
  user: {
    id: ?string,
    first_name: ?string,
    last_name: ?string,
    name: ?string,
    avatar: ?{
      id: string,
      smallThumbnail: ?string,
    },
  },
  skill?: ?{
    id: ?string,
    title: string,
    price: ?number | ?string,
    is_hireable?: booelean,
  },
  transparent?: boolean,
  showSkillTitle?: boolean,
};

const PureMapMarker = ({
  className,
  skill,
  to,
  href,
  onClick,
  zIndex,
  target,
  user,
  transparent,
  showSkillTitle,
  zIndexHover,
}: Props) => {
  const Element = to || href ? Anchor : Button;

  return (
    <Element
      to={to}
      style={{ zIndex }}
      target={target}
      href={href}
      onClick={onClick}
      role="button"
      tabIndex="-1"
    >
      <MapMarker
        className={classNames([
          styles['map-marker'],
          transparent && styles.transparent,
          className,
        ])}
        zindex={zIndex}
        skill={skill}
        showSkillTitle={showSkillTitle}
        user={user}
        zIndexHover={zIndexHover}
      />
    </Element>
  );
};

export default PureMapMarker;
