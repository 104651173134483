/**
 * @generated SignedSource<<93a4cb57ae3ff3e555359532d014cb99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddPayoutInput = {
  bank_account_token?: string | null;
  clientMutationId?: string | null;
  payout_method_id?: string | null;
  seller_account_id?: string | null;
};
export type AddPayoutMethodMutation$variables = {
  input: AddPayoutInput;
};
export type AddPayoutMethodMutation$data = {
  readonly addPayout: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly payoutMethods: {
      readonly bank_account: {
        readonly account_holder_name: string | null;
        readonly id: string;
        readonly last4: string | null;
        readonly routing_number: string | null;
      } | null;
      readonly id: string;
    } | null;
    readonly user: {
      readonly id: string;
      readonly settings: {
        readonly has_valid_payout_method: boolean | null;
        readonly id: string;
      } | null;
    } | null;
  } | null;
};
export type AddPayoutMethodMutation = {
  response: AddPayoutMethodMutation$data;
  variables: AddPayoutMethodMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PayoutMethods",
  "kind": "LinkedField",
  "name": "payoutMethods",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccount",
      "kind": "LinkedField",
      "name": "bank_account",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "account_holder_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "routing_number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last4",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Settings",
      "kind": "LinkedField",
      "name": "settings",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "has_valid_payout_method",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddPayoutMethodMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddPayoutPayload",
        "kind": "LinkedField",
        "name": "addPayout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddPayoutMethodMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddPayoutPayload",
        "kind": "LinkedField",
        "name": "addPayout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1010a11e9ea70c9b9473ccb165309510",
    "id": null,
    "metadata": {},
    "name": "AddPayoutMethodMutation",
    "operationKind": "mutation",
    "text": "mutation AddPayoutMethodMutation(\n  $input: AddPayoutInput!\n) {\n  addPayout(input: $input) {\n    error {\n      message\n      id\n    }\n    payoutMethods {\n      id\n      bank_account {\n        id\n        account_holder_name\n        routing_number\n        last4\n      }\n    }\n    user {\n      id\n      settings {\n        id\n        has_valid_payout_method\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b406e90a6b6dc5b26f3ad2f14ac41077";

export default node;
