/* @flow */
import React from 'react';
import { responsive } from '../../utils';

/**
 * Simple Picture component to render image on different screens
 *
 * */
type Props = {
  className?: string,
  pictureClassName?: string,
  src: string,
  srcSm?: string,
  srcMd?: string,
  srcLg?: string,
  srcXlg?: string,
  alt: string,
};

const Picture = ({
  className,
  pictureClassName,
  src,
  srcSm,
  srcMd,
  srcLg,
  srcXlg,
  alt,
  ...rest
}: Props) => (
  <picture className={pictureClassName}>
    {srcXlg && (
      <source
        srcSet={srcXlg}
        media={`(min-width: ${responsive.minDeviceWidthXLG}px)`}
      />
    )}
    {srcLg && (
      <source
        srcSet={srcLg}
        media={`(min-width: ${responsive.minDeviceWidthLG}px)`}
      />
    )}
    {srcMd && (
      <source
        srcSet={srcMd}
        media={`(min-width: ${responsive.minDeviceWidthMD}px)`}
      />
    )}
    {srcSm && (
      <source
        srcSet={srcSm}
        media={`(min-width: ${responsive.minDeviceWidthSM}px)`}
      />
    )}
    <img className={className} src={src} alt={alt} {...rest} />
  </picture>
);

export default Picture;
