/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import urlJoin from 'url-join';

import { loadStripe } from './stripe';

import type {
  plStripeCheckoutHelpers_checkoutSetupSessionQueryVariables,
  plStripeCheckoutHelpers_checkoutSetupSessionQueryResponse,
} from './__generated__/plStripeCheckoutHelpers_checkoutSetupSessionQuery.graphql';

/**
 * Queries a new checkout session with mode='setup' from the backend
 */
const checkoutSetupSessionQuery = graphql`
  query plStripeCheckoutHelpers_checkoutSetupSessionQuery(
    $success_url: String!
    $cancel_url: String!
  ) {
    viewer {
      paymentMethodsCreation {
        checkoutSetupSession(
          success_url: $success_url
          cancel_url: $cancel_url
        ) {
          id
          # Raw stripe sessionId
          raw_id
          error {
            message
          }
        }
      }
    }
  }
`;

/**
 * Fetches a new checkout session with mode='setup' from the backend
 */
const _fetchCheckoutSetupSession = async (
  variables: plStripeCheckoutHelpers_checkoutSetupSessionQueryVariables,
  cacheConfig?: { force?: boolean },
): Promise<plStripeCheckoutHelpers_checkoutSetupSessionQueryResponse> => {
  // eslint-disable-next-line global-require
  const { fetchQuery } = await require('../graphql');
  return fetchQuery(
    checkoutSetupSessionQuery,
    variables,
    cacheConfig || { force: true },
  ).toPromise();
};

/**
 * Redirects to a new checkout session
 */
const redirectToCheckoutSetup = async (
  {
    success_url,
    cancel_url,
  }: {
    success_url: string,
    cancel_url: string,
  },
  { cacheConfig }: { cacheConfig?: { force?: boolean } } = {},
): Promise<{ error?: ?{ message: string } }> => {
  let backendQueryResult;
  try {
    backendQueryResult = await _fetchCheckoutSetupSession(
      {
        success_url: urlJoin(
          success_url || window.location.href,
          '?sc_checkout=success&sc_sid={CHECKOUT_SESSION_ID}',
        ),
        cancel_url: urlJoin(
          cancel_url || window.location.href,
          '?sc_checkout=cancel',
        ),
      },
      cacheConfig,
    );
  } catch (err) {
    return { error: err };
  }

  const { checkoutSetupSession } =
    backendQueryResult?.viewer?.paymentMethodsCreation || {};

  const CHECKOUT_SESSION_ID = checkoutSetupSession?.raw_id;

  const stripe = await loadStripe();

  /* redirect to checkout */
  const result = CHECKOUT_SESSION_ID
    ? await stripe.redirectToCheckout({ sessionId: CHECKOUT_SESSION_ID })
    : {
        error: {
          message:
            checkoutSetupSession?.error?.message ||
            'Could not fetch checkout session. Please try again.',
        },
      };

  return result;
};

export default {
  redirectToCheckoutSetup,
};
