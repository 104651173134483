import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import pluralize from 'pluralize';
import { skillHelpers } from '@pluralcom/plural-js-utils';
import _ from 'lodash';

import {
  Avatar,
  AvatarHorizontalOverlay,
  ButtonFlat,
  Divider,
  Text,
} from '@pluralcom/blueprint';

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { getUserFullName } from '@pluralcom/plural-js-utils/lib/userHelpers/userHelpers';
import { createFragmentContainer } from 'react-relay';
import { useMedia } from 'use-media';
import { responsive } from '../../../../utils';

import TagHiringType from '../../../TagHiringType/TagHiringType';
import FinishYourSkillModal from '../../../FinishYourSkillModal/FinishYourSkillModal';

import EditButton from '../EditButton/EditButton';
import SaveButton from '../SaveButton/SaveButton';

import styles from './SkillCardMiniHeader.module.scss';

import type { SkillCardMiniHeader_skill$data } from './__generated__/SkillCardMiniHeader_skill.graphql';

interface UserProps {
  /** user ID */
  id: string;
  /** user recommendations */
  recommendations: {
    /** received */
    received: {
      /** total count */
      totalCount: number;
    };
  };
  /** user avatar */
  avatar: {
    /** avatar ID */
    id: string;
    /** smallThumbnail */
    smallThumbnail: string;
  };
  /** user first name */
  first_name?: string;
}

export interface SkillCardMiniHeaderProps {
  /** skill */
  skill: SkillCardMiniHeader_skill$data;
  /** isOwner */
  isOwner?: boolean;
  /** className */
  className?: string;
  /** parentID */
  parentID?: string;
  /** totalCount */
  totalCount?: number;
  /** user */
  user?: UserProps;
  /** size */
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
}

const ICON_SIZE_MAP = {
  xs: 'xs',
  sm: 'xs',
  md: 'xs',
  lg: 'lg',
  xl: 'lg',
  xxl: 'lg',
  xxxl: 'lg',
};
const TYPOGRAPHY_SIZE_MAP: {
  [key: string]: React.ComponentProps<typeof Text>['stickToBreakpoint'];
} = {
  xs: 'xs',
  sm: 'xs',
  md: 'md',
  lg: 'lg',
  xl: 'lg',
  xxl: 'lg',
  xxxl: 'lg',
};

/**
 * FIGMA v0.0.1
 * SkillCardMiniHeader component.
 */
const SkillCardMiniHeader = ({
  className,
  skill,
  isOwner,
  parentID,
  totalCount,
  user,
  size,
}: SkillCardMiniHeaderProps) => {
  const isLg = useMedia({ minWidth: responsive.minDeviceWidthLG });
  const iconSize = ICON_SIZE_MAP[size];
  const typographySize = TYPOGRAPHY_SIZE_MAP[size];
  const screen_size = iconSize ?? (!isLg ? 'xs' : 'lg');

  const { title, likes, is_hobby, hiring_types = [] } = skill;
  const { recommendations } = user || skill?.user || {};
  // @ts-ignore
  const { received: { totalCount: totalCountRecommendations = 0 } = {} } =
    recommendations || {};
  const {
    // @ts-ignore
    users: { totalCount: likesTotalCount = 0, edges: likesUsers = [] } = {},
  } = likes || {};
  const userName =
    (user || skill?.user) && getUserFullName(user || skill?.user);

  const [offersTagsList, setOffersTagsList] = useState<Array<string>>([]);

  /** UseEffect to set hiring_types if is_hobby is true else it will set default giting type */
  useEffect(() => {
    let currentOffersTagsList = [is_hobby ? 'Hobby' : 'Pro'];
    if (!is_hobby) {
      if (_.isArray(hiring_types)) {
        currentOffersTagsList = [...currentOffersTagsList, ...hiring_types];
      } else {
        currentOffersTagsList = [...currentOffersTagsList];
      }
    }
    setOffersTagsList(currentOffersTagsList);
  }, [hiring_types, is_hobby]);

  const [
    isOpenModalFinishGhostSkill,
    setIsOpenModalFinishGhostSkill,
  ] = useState(false);

  const isGhostOrUnpublished = skillHelpers.isGhostOrUnpublished(skill);
  return (
    <header
      className={classNames([
        styles.container,
        styles[`container-${screen_size}`],
        className,
      ])}
    >
      {isOwner ? (
        <EditButton
          skill={skill}
          parentID={parentID || user?.id || skill?.user?.id}
          totalCount={totalCount || 0}
          // @ts-ignore
          size={screen_size}
        />
      ) : null}
      {!isOwner && (
        <SaveButton
          skill={skill}
          // @ts-ignore
          size={screen_size}
        />
      )}
      <Text
        fontSizeType="t3"
        fontWeight="black"
        element="span"
        stickToBreakpoint={typographySize ?? (isLg ? 'lg' : 'xs')}
        className={styles.title}
      >
        {title}
      </Text>

      <div className={styles.tags}>
        {offersTagsList.map((type) => (
          <TagHiringType
            key={type}
            className={styles.tag}
            type={typeof type === 'object' ? JSON.stringify(type) : type}
            size={screen_size === 'xs' ? 'xs' : 'md'}
          />
        ))}
      </div>
      {likesTotalCount && likesUsers?.length > 0 ? (
        <div
          className={classNames([styles.likes, styles[`likes-${screen_size}`]])}
        >
          <AvatarHorizontalOverlay
            users={likesUsers?.map((el) => el.node)}
            className={styles['likes-avatar']}
            borderType="white-border"
          />
          <Text
            fontSizeType={isLg ? 't8' : 't10'}
            element="span"
            className={styles['likes-text']}
            fontWeight="bold"
            stickToBreakpoint={typographySize ?? (isLg ? 'lg' : 'xs')}
          >
            {`${likesTotalCount} ${pluralize('Like', likesTotalCount)}`}
          </Text>
        </div>
      ) : null}

      {!isOwner && userName && !isGhostOrUnpublished ? (
        <>
          <Divider
            uiType="gray"
            orientation="horizontal"
            className={classNames([
              styles.divider,
              styles[`divider-${screen_size}`],
            ])}
            gy="0"
          />
          <div
            className={classNames([
              styles['user-info-container'],
              styles[`user-info-container-${screen_size}`],
            ])}
          >
            <Avatar
              borderType="white-border"
              shadowType
              avatar={user ? user?.avatar : skill?.user?.avatar}
              // @ts-ignore
              user={user || skill?.user}
              size={screen_size === 'xs' ? 'sm' : 'md'}
              imageProps={{ className: styles.avatar }}
            />
            <div className={styles.recommend}>
              <Text
                fontSizeType={screen_size === 'xs' ? 't10' : 't6'}
                className={styles['title-username']}
                fontWeight="bold"
                element="span"
                stickToBreakpoint={typographySize ?? (isLg ? 'lg' : 'xs')}
              >
                {userName}
              </Text>
              {totalCountRecommendations ? (
                <Text
                  fontSizeType={screen_size === 'xs' ? 't11' : 't7'}
                  className={styles['recommend-text']}
                  fontWeight="regular"
                  element="span"
                  stickToBreakpoint={typographySize ?? (isLg ? 'lg' : 'xs')}
                >
                  {`${totalCountRecommendations} ${pluralize(
                    'Recommend',
                    totalCountRecommendations,
                  )}`}
                </Text>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
      {isOwner && isGhostOrUnpublished ? (
        <>
          <Divider
            uiType="gray"
            orientation="horizontal"
            className={classNames([
              styles.divider,
              styles[`divider-${screen_size}`],
            ])}
            gy="0"
          />
          <span
            className={classNames([
              styles.finish,
              styles[`finish-${screen_size}`],
            ])}
          >
            <div className={styles['owner-container']}>
              <Text
                fontSizeType="t9"
                className={styles.finish__text}
                fontWeight="regular"
                element="span"
                stickToBreakpoint={typographySize ?? (isLg ? 'lg' : 'xs')}
              >
                <Text
                  fontSizeType="t9"
                  fontWeight="bold"
                  element="span"
                  className={styles.draft}
                >
                  Draft{' '}
                </Text>
                - Finish your skill to get featured!
              </Text>
            </div>
            <ButtonFlat
              id={styles['finish-btn']}
              uiType="secondary"
              size={screen_size === 'xs' ? 'sm' : 'md'}
              fullWidth={screen_size === 'xs'}
              className={styles['owner-button']}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsOpenModalFinishGhostSkill(true);
              }}
              textProps={{
                className: classNames([
                  styles['owner-button'],
                  styles['owner-button-height'],
                ]),
              }}
              text="Finish"
            />
          </span>
        </>
      ) : null}
      {isOwner && isGhostOrUnpublished ? (
        <FinishYourSkillModal
          isOpen={isOpenModalFinishGhostSkill}
          onToggle={() => setIsOpenModalFinishGhostSkill((val) => !val)}
          skill={skill}
          userFirstName={user?.first_name || skill?.user?.first_name}
        />
      ) : null}
    </header>
  );
};
export { SkillCardMiniHeader as PureSkillCardMiniHeader };

export default createFragmentContainer(SkillCardMiniHeader, {
  skill: graphql`
    fragment SkillCardMiniHeader_skill on Skill
    @argumentDefinitions(
      isSkillCardPreview: { type: "Boolean", defaultValue: false }
      isSkillCardLean: { type: "Boolean", defaultValue: false }
    ) {
      id
      title
      # description # not used for either preview or lean
      is_hobby
      hiring_types
      likes {
        id
        viewerHasLiked
        users(first: 3)
          @connection(key: "SkillCardMiniHeader_likes_users")
          @include(if: $isSkillCardPreview) {
          totalCount
          edges {
            node {
              id
              first_name
              last_name
              profile_color
              avatar {
                id
                smallThumbnail
              }
            }
          }
        }
      }
      user @include(if: $isSkillCardLean) {
        id
        first_name
        last_name
        name
        profile_color
        avatar {
          id
          smallThumbnail
        }
        recommendations {
          id
          received {
            totalCount
          }
        }
      }
      is_draft @include(if: $isSkillCardPreview)
      is_ghost @include(if: $isSkillCardPreview)
      published @include(if: $isSkillCardPreview)
    }
  `,
});
