/* @flow */
import React, { useCallback, useRef, useEffect } from 'react';
import ReactMapGL, { MapRef } from 'react-map-gl';
import { localStorageService } from '@pluralcom/plural-web-utils';

import { useDebouncedCallback } from 'use-debounce';
import { analyticsHelpers } from '@pluralcom/plural-js-utils';
import PLURALBOT from '@pluralcom/plural-js-utils/lib/pluralbot';
import { MapButtonLocateMe } from '@pluralcom/blueprint';

import classNames from 'classnames';
// 3rd-party easing functions
import { easeCubic } from 'd3-ease';

import {
  MAPBOX_ACCESS_TOKEN,
  MAPBOX_STYLE_URL,
} from '../../../assets/data/APIKeys';
import MapCurrentLocationPin from '../MapCurrentLocationPin/MapCurrentLocationPin';
import { mixpanelHelpers } from '../../../utils';

import styles from './MapboxMap.module.scss';

const mapTransition = {
  transitionDuration: 1000,
  // transitionInterpolator: new FlyToInterpolator(),
  transitionEasing: easeCubic,
};

type Props = {
  center: Object,
  onChange: Function,
  children: React$Node,
  userLocation: Object,
  zoom: number,
  withLocateMeBtn: number,
  locateMeButtonClassName: string,
  onClickLocateMe: Function,
  renderSiblings: Function,
  defaultZoom: number,
  width?: number | string,
  height?: number | string,
  logoPosition?: String,
};

/** this is need to not go out of the bounds of map,
 *  otherwise data would not be fetched */
const getMaxBound = (cord, max) => {
  const multiply = cord < 0 ? -1 : 1;
  return Math.min(Math.abs(cord), max) * multiply;
};

const getMapBound = (mapBoxMap) => {
  const mapGL = mapBoxMap.getMap();
  const bounds = mapGL.getBounds();
  const res = {
    ne: {
      lat: getMaxBound(bounds._ne.lat, 80),
      lng: getMaxBound(bounds._ne.lng, 170),
    },
    sw: {
      lat: getMaxBound(bounds._sw.lat, 80),
      lng: getMaxBound(bounds._sw.lng, 125),
    },
  };
  return res;
};

/**
 * Mapbox Map
 *
 * notes:
 * - sometimes map doesn't display, temp fix is update browserlist -> https://github.com/mapbox/mapbox-gl-js/issues/10565
 */
const MapboxMap = ({
  center,
  onChange,
  children,
  userLocation,
  zoom,
  withLocateMeBtn,
  locateMeButtonClassName,
  onClickLocateMe,
  defaultZoom = 12,
  renderSiblings,
  width,
  height,
  logoPosition = 'bottom-left',
  ...rest
}: Props) => {
  const KEYS = {
    ipInfo: 'ipInfo',
  };
  const ipInfo = localStorageService.getItem(KEYS.ipInfo);
  const { latitude, longitude } = center ||
    (ipInfo && {
      latitude: ipInfo?.latitude,
      longitude: ipInfo?.longitude,
    }) || {
      latitude: PLURALBOT.user?.location?.latitude,
      longitude: PLURALBOT.user?.location?.longitude,
    };

  const [viewport, setViewport] = React.useState({
    latitude,
    longitude,
    zoom,
  });
  const [isLoaded, setIsLoaded] = React.useState(false);
  const mapRef = useRef<MapRef>(null);

  /** change viewport when center changes effect */
  useEffect(() => {
    setViewport({
      latitude,
      longitude,
      zoom,
      ...mapTransition,
    });
    mapRef.current?.flyTo({
      center: [longitude, latitude],
      duration: mapTransition.transitionDuration,
      zoom,
    });
  }, [latitude, longitude, zoom]);

  const onChangeSpy = (_viewport) => {
    if (onChange) {
      onChange({
        ..._viewport,
        bounds: getMapBound(mapRef.current),
      });
    }
  };

  /** Handle scroll to increase performance  */
  const { callback: handleZoomChange } = useDebouncedCallback(() => {
    onChangeSpy(viewport);
  }, 100);

  /**
   * Sets the center of the map to user location and zoom to default zoom
   */
  const _handleClickLocateBtn = useCallback(() => {
    if (onClickLocateMe) {
      const setNewCenter = (newViewport) => {
        mapRef.current?.flyTo({
          center: [newViewport?.longitude, newViewport?.latitude],
          duration: mapTransition.transitionDuration,
          zoom: newViewport.zoom,
        });
        mapRef.current?.setZoom(newViewport.zoom);
        return setViewport({
          ...viewport,
          bounds: getMapBound(mapRef.current),
          zoom: newViewport.zoom,
          latitude: newViewport?.latitude,
          longitude: newViewport?.longitude,
          ...mapTransition,
        });
      };
      onClickLocateMe(setNewCenter);
      return;
    }
    if (userLocation && onChange) {
      const newViewport = {
        zoom: defaultZoom,
        bounds: getMapBound(mapRef.current),
        ...userLocation,
        ...mapTransition,
      };
      mapRef.current?.flyTo({
        center: [userLocation.longitude, userLocation.latitude],
        duration: mapTransition.transitionDuration,
        zoom: defaultZoom,
      });
      setViewport(newViewport);

      /** need to wait until animation finished */
      setTimeout(() => {
        onChange(newViewport);
      }, mapTransition.transitionDuration);
    }
    mixpanelHelpers.trackEvent(analyticsHelpers.events.MAP_BUTTONLOCATEME.name);
  }, [onChange, defaultZoom, viewport, onClickLocateMe, userLocation]);

  return (
    <>
      <ReactMapGL
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        mapStyle={MAPBOX_STYLE_URL}
        className={classNames([styles.map, rest?.className])}
        style={{
          width: width || '100%',
          height: height || `${window.innerHeight}px`,
        }}
        {...viewport}
        onMove={(evt) => setViewport(evt.viewState)}
        onMouseUp={() => onChangeSpy(viewport)}
        onTouchEnd={() => onChangeSpy(viewport)}
        onWheel={handleZoomChange}
        ref={mapRef}
        attributionControl={false}
        logoPosition={logoPosition}
        maxPitch={85}
        maxZoom={24}
        onLoad={() => setIsLoaded(true)}
        {...rest}
      >
        {children}
        {isLoaded && <>{renderSiblings ? renderSiblings() : null}</>}
        {Boolean(userLocation) && (
          <MapCurrentLocationPin
            key="userLocation"
            lat={userLocation?.latitude}
            lng={userLocation?.longitude}
          />
        )}
      </ReactMapGL>
      {Boolean(withLocateMeBtn && userLocation) && (
        <MapButtonLocateMe
          className={classNames([
            styles['btn-locate-me'],
            locateMeButtonClassName,
          ])}
          onClick={_handleClickLocateBtn}
        />
      )}
    </>
  );
};

export default MapboxMap;
