/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';

import { commitMutation } from '../..';
import type { LocationType } from '../../../../flow';

const mutation = graphql`
  mutation RequestPaymentMutation($input: RequestPaymentInput!) {
    requestPayment(input: $input) {
      dealEdge {
        node {
          id
          ...DealsItem_deal
          ...DealControls_deal
          payments(first: 1) {
            edges {
              node {
                id
                thread_id
                status
                amount
                created_at
              }
            }
          }
        }
      }
      skillEdge {
        node {
          id
          # Optimistic update for profile skills
          ...SkillCardMini_skill @arguments(isSkillCardPreview: true)
        }
      }
      error {
        message
      }
    }
  }
`;

/**
 * RequestPayment
 *
 * Valid combinations of optional inputs
 * ```js
   // One of these is required to identify the user
   { from_id, email, phone }
   // One of these is required to identify the user
   { skill_id, ~skill_title~ }
   ```
 */
export default (
  {
    from_id,
    email,
    phone,
    skill_id,
    skill_title,
    amount,
    note,
    service_description,
    service_location_type,
    service_starts_at,
    service_duration,
    service_location,
    hiring_type,
    skill_offer_id,
  }: {
    from_id?: string,
    email?: string,
    phone?: string,
    skill_id?: string,
    skill_title?: string,
    amount: string,
    note?: string,
    service_description?: string,
    service_location_type?: string,
    service_location: LocationType,
    service_starts_at?: string,
    service_duration?: number,
    hiring_type?: string,
    skill_offer_id?: string,
  },
  { viewer }: { id?: string } = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        from_id,
        email,
        phone,
        skill_id,
        skill_title,
        amount: parseFloat(amount.toString()),
        note,
        service_description,
        service_location_type,
        service_location:
          service_location && pick(service_location, ['longitude', 'latitude']),
        service_starts_at,
        service_duration,
        hiring_type,
        skill_offer_id,
      },
    };

    const configs = [
      {
        type: 'RANGE_ADD',
        parentID: 'client:root:viewer',
        connectionInfo: [
          {
            key: 'DealsMessenger_deals',
            rangeBehavior: 'prepend',
            filters: { otherUserId: from_id },
          },
        ],
        edgeName: 'dealEdge',
      },
      viewer?.id
        ? {
            type: 'RANGE_ADD',
            parentID: viewer?.id,
            connectionInfo: [
              {
                key: 'Profile_skills',
                rangeBehavior: 'append',
              },
            ],
            edgeName: 'skillEdge',
          }
        : null,
    ].filter(Boolean);

    commitMutation({
      mutation,
      variables,
      configs,

      onCompleted: resolve,
      onError: reject,
    });
  });
