/* @flow */
import React, { useState } from 'react';
import { useMedia } from 'use-media';
import {
  TouchableOpacity,
  MapLegend as MapLegendBlueprint,
} from '@pluralcom/blueprint';

import styles from './MapLegend.module.scss';
import ModalMapLegend from '../ModalMapLegend/ModalMapLegend';

import { responsive } from '../../../../../utils';

const MapLegend = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const isDesktop = useMedia({ minWidth: responsive.minDeviceWidthMD });
  return (
    <>
      <TouchableOpacity
        className={styles['touchable-container']}
        onClick={() => setIsOpenModal(true)}
      >
        <MapLegendBlueprint
          className={styles.container}
          size={isDesktop ? 'md' : 'xs'}
        />
      </TouchableOpacity>
      <ModalMapLegend
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
      />
    </>
  );
};

export default MapLegend;
