/* @flow */
import React from 'react';
import classNames from 'classnames';
import { s3Media } from '@pluralcom/plural-js-utils';
import { Icon } from '@pluralcom/blueprint';
import ModalOnBoarding from '../../../../ModalOnBoarding/ModalOnBoarding';

import styles from './ModalMapLegend.module.scss';

type Props = {
  isOpen?: boolean,
  toggle: Function,
};

const imageUriLg = s3Media.getS3MediaUrlWeb(
  'components/ModalMapLegend/modal_map_legend-lg@2x.jpg',
);
const imageUriXlg = s3Media.getS3MediaUrlWeb(
  'components/ModalMapLegend/modal_map_legend-xlg@2x.jpg',
);

const ModalMapLegend = ({ toggle, isOpen = false }: Props) => (
  <ModalOnBoarding
    isOpen={isOpen}
    toggle={toggle}
    imgUrl={imageUriLg}
    imgUrlLg={imageUriLg}
    imgUrlXlg={imageUriXlg}
    titleClassName={styles.title}
    title="People & Demand Map Pins"
    text={() => (
      <>
        <span>
          <span className={classNames(styles.blue, styles.bold)}>
            <Icon
              type="fa"
              icon={['fas', 'circle']}
              className={styles.blue}
              size="md"
            />{' '}
            People
          </span>{' '}
          map pins are blue and show you locations of other users.
        </span>
        <br />
        <span className={styles.devider} />
        <br />
        <span>
          <span className={classNames(styles.red, styles.bold)}>
            <Icon
              type="fa"
              icon={['fas', 'circle']}
              className={styles.red}
              size="md"
            />{' '}
            Demand
          </span>{' '}
          map pins are red and show you which skills and services other users
          are looking for.
        </span>
      </>
    )}
    primaryButton={{
      text: 'Got it!',
      onClick: toggle,
    }}
  />
);

export default ModalMapLegend;
