/* @flow */
import React from 'react';
import { Anchor } from '@pluralcom/blueprint';

type Props = {
  children: string | ReactNode,
  className?: ?string,
  contact: 'hello' | 'support' | 'help' | 'careers',
  subject: string,
};

const ContactPluralMail = ({
  children,
  className,
  contact = 'hello',
  subject = '',
  ...rest
}: Props) => (
  <Anchor
    {...rest}
    href={`mailto:${contact}@plural.com${subject ? `?subject=${subject}` : ''}`}
    className={className}
  >
    {children || `${contact}@plural.com`}
  </Anchor>
);

export default ContactPluralMail;
