/* @flow */
import React from 'react';
import classNames from 'classnames';
import { TagColorful } from '@pluralcom/blueprint';

import offersHelpers from '@pluralcom/plural-js-utils/lib/offers/offersHelpers';

import styles from './TagHiringType.module.scss';

type Props = {
  type?: string,
  className?: string,
  size?: 'xs' | 'sm' | 'md' | 'lg',
};

const TagHiringType = ({ type, className, size = 'md' }: Props) => {
  const colorsStyle = offersHelpers.HIRING_TYPES_COLORS[type];
  return (
    <TagColorful
      className={classNames([styles.container, className])}
      size={size}
      textColor={colorsStyle?.textColor}
      backgroundColor={colorsStyle?.backgroundColor}
      backgroundColorActive={colorsStyle?.backgroundColor}
      backgroundColorHover={colorsStyle?.backgroundColor || ''}
    >
      {offersHelpers.HIRING_TYPES_DISPLAY_TEXTS[type]?.title || type}
    </TagColorful>
  );
};

export default TagHiringType;
