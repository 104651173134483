/* @flow */
import { ConnectionHandler } from 'relay-runtime';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import requestSubscription from '../requestSubscription/requestSubscription';

const subscription = graphql`
  subscription threadsSubscribeSubscription {
    activitiesSubscribe(type: THREADS) {
      id
      sorted_by
      thread_id
      seen_at
      archived_at
      ...MessengerListItem_data
    }
  }
`;

const threadsSubscribe = () =>
  requestSubscription({
    subscription,
    updater: (store) => {
      const newDoc = store.getRootField('activitiesSubscribe');
      const parentProxy = store.get('client:root:viewer');
      if (!parentProxy) {
        return;
      }
      const connection = ConnectionHandler.getConnection(
        parentProxy,
        'Messenger_activities',
      );

      if (!connection) {
        return;
      }

      const newId = newDoc.getValue('id');

      const existingRecords = connection.getLinkedRecords('edges');
      const recordAlreadyExists = existingRecords.some((existingRecord) => {
        const node = existingRecord.getLinkedRecord('node');
        const existingId = node.getValue('id');

        return existingId === newId;
      });

      if (newDoc.getValue('deleted_at')) {
        ConnectionHandler.deleteNode(connection, newId);
        return;
      }

      if (!recordAlreadyExists) {
        const edge = ConnectionHandler.createEdge(
          store,
          connection,
          newDoc,
          'ActivityEdge',
        );
        ConnectionHandler.insertEdgeBefore(connection, edge);
      }
    },
  });

export default threadsSubscribe;
