/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation SigninFacebookMutation($input: SignInFacebookInput!) {
    signinFacebook(input: $input) {
      profile {
        id
        username
        verified_facebook
        # To update the avatar upon connecting facebook if no avatar existed
        profile_color
        avatar {
          id
          smallThumbnail
          thumbnail
          medium
        }
        remote_user_facebook {
          id
          name
        }
      }
      roles
      intercom_hash
      error {
        message
      }
    }
  }
`;

const connectMutation = graphql`
  mutation SigninFacebookConnectMutation($input: SignInFacebookInput!) {
    signinFacebook(input: $input) {
      profile {
        verified_facebook
      }
      error {
        message
      }
    }
  }
`;

/**
 * Pass connectOnly if you wish to show an optimistic update
 */
const SigninFacebookMutation = ({
  accessToken,
  with_auth,
}: {
  accessToken: string,
  with_auth?: boolean,
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        accessToken,
        with_auth,
      },
    };

    commitMutation({
      mutation: with_auth ? mutation : connectMutation,
      variables,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default SigninFacebookMutation;
