import React, { useState } from 'react';
import classNames from 'classnames';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';

import { Card, Text, AvatarHorizontalOverlay } from '@pluralcom/blueprint';

import styles from './ConnectSection.module.scss';
import ModalConnectSection from './components/ModalConnectSection/ModalConnectSection';
import { InputUsers_viewer$data } from '../InputUsers/__generated__/InputUsers_viewer.graphql';

interface ConnectSectionProps {
  /** custom classname */
  className?: string;
  /** Viewer object */
  viewer?: InputUsers_viewer$data;
  /** Function to set the state of the modal */
  setIsConnectSectionModalOpen?: Function;
}

/**
 * - Figma v0.0.2
 * - ConnectSection component
 *
 */
const ConnectSection = ({ className, ...rest }: ConnectSectionProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { viewer, setIsConnectSectionModalOpen } = rest;
  const data = useFragment(
    graphql`
      fragment ConnectSection_viewer on ViewerType
      @argumentDefinitions(
        longitude: { type: "Float" }
        latitude: { type: "Float" }
      ) {
        listSearch: search(
          term: ""
          longitude: $longitude
          latitude: $latitude
        ) {
          peopleYouMayKnow(first: 3) {
            edges {
              node {
                id
                first_name
                last_name
                profile_color
                avatar {
                  id
                  smallThumbnail
                }
              }
            }
          }
        }
      }
    `,
    viewer || null,
  );
  const users =
    data?.listSearch?.peopleYouMayKnow?.edges?.map(({ node }) => node) || [];
  return (
    <>
      <div className={classNames([styles.container, className])}>
        <Card
          uiType="border-elevation"
          className={styles.card}
          borderRadiusType="border-radius-type-4"
          {...rest}
          // @ts-ignore
          onClick={(e) => {
            e?.stopPropagation();
            e?.preventDefault();
            if (setIsConnectSectionModalOpen) {
              setIsConnectSectionModalOpen(true);
            }
            setIsModalOpen(true);
          }}
        >
          <div className={styles['text-container']}>
            <Text
              fontSizeType="t5"
              element="span"
              stickToBreakpoint="lg"
              className={styles.text}
            >
              🌎 Find Friends
            </Text>
            <Text fontSizeType="t9" element="span" className={styles.text}>
              Connect other networks to find your trusted friends.
            </Text>
          </div>
          <AvatarHorizontalOverlay
            users={users}
            size="md"
            className={styles['avatar-overlay']}
          />
        </Card>
      </div>
      <ModalConnectSection
        isOpen={isModalOpen}
        toggleModal={() => {
          if (setIsConnectSectionModalOpen) {
            setIsConnectSectionModalOpen(false);
          }
          setIsModalOpen(false);
        }}
        viewer={viewer}
      />
    </>
  );
};

export default ConnectSection;
