/* @flow */
import moment from 'moment';
import validator from 'validator';
import { inputsMaxLengths } from '../../assets/data';

const isValidEmail = (email: string): boolean => validator.isEmail(email);

const isNumeric = (str: string, opts): boolean =>
  validator.isNumeric(str, opts);

const isAlphaChar = (ch: string) =>
  typeof ch === 'string' &&
  ch.length === 1 &&
  ((ch >= 'a' && ch <= 'z') || (ch >= 'A' && ch <= 'Z'));

const isUrlFuzzy = (url: string) =>
  /^([^\s.]+\.[^\s]{2,}|localhost|([^\s.]+:\/\/localhost))$/.test(url);

const isEmpty = (str: string): boolean => {
  if (str) {
    return validator.isEmpty(str);
  }
  return true;
};
const isValidPluralPasswordLength = (password: string): boolean =>
  password.length >= 6 && password.length <= inputsMaxLengths.password;

const isValidCreditCardNumber = (cardNumber: string): boolean =>
  isNumeric(cardNumber) &&
  cardNumber.length >= 16 &&
  cardNumber.length <= inputsMaxLengths.creditCardNumber;

/** Checks validaity of credit card expiry date (mm/yyyy) */
const isValidExpiryDate = (dateString: string) => {
  if (!dateString) {
    return false;
  }

  let [expiryMonth, expiryYear] = dateString.split('/');

  if (
    !expiryMonth ||
    !expiryYear ||
    expiryMonth.length !== 2 ||
    expiryYear.length !== 4
  ) {
    return false;
  }

  expiryMonth = parseInt(expiryMonth, 10);
  expiryYear = parseInt(expiryYear, 10);

  if (
    expiryMonth <= 0 ||
    expiryMonth > 12 ||
    expiryYear < 1970 ||
    expiryYear > 2200
  ) {
    return false;
  }

  const today = new Date();
  const expiryDate = new Date();
  expiryDate.setFullYear(expiryYear, expiryMonth, 1);

  return expiryDate > today;
};

type isManualDateStringFormat = 'MM/DD/YYYY' | 'DD/MM/YYYY' | 'MM/YYYY';
const isManualDateString = (format: isManualDateStringFormat) => (
  dateString: string,
) => {
  if (!dateString || dateString.length > 10) {
    return false;
  }

  let splitDateString: Array<string>;
  let dayStr: string;
  let monthStr: string;
  let yearStr: string;

  if (format === 'MM/DD/YYYY') {
    splitDateString = dateString.split('/');
    [monthStr, dayStr, yearStr] = splitDateString;
  } else if (format === 'DD/MM/YYYY') {
    splitDateString = dateString.split('/');
    [dayStr, monthStr, yearStr] = splitDateString;
  } else if (format === 'MM/YYYY') {
    splitDateString = dateString.split('/');
    dayStr = '01';
    [monthStr, yearStr] = splitDateString;
  } else {
    return false;
  }

  if (
    !dayStr ||
    dayStr.length !== 2 ||
    !monthStr ||
    monthStr.length !== 2 ||
    !yearStr ||
    yearStr.length !== 4
  ) {
    return false;
  }

  return moment(dateString, format).isValid();
};

const isManualDateStringSlashedDMY = isManualDateString('DD/MM/YYYY');
const isManualDateStringSlashedMDY = isManualDateString('MM/DD/YYYY');
const isManualDateStringSlashedMY = isManualDateString('MM/YYYY');

const isValidAuthVerificationCode = (str: string) =>
  str.length === inputsMaxLengths.authVerificationCode && isNumeric(str);

export default {
  isValidEmail,
  isAlpha: validator.isAlpha,
  isAlphaChar,
  isNumeric,
  isUrlFuzzy,
  isEmpty,
  isValidPluralPasswordLength,
  isValidCreditCardNumber,
  isValidExpiryDate,
  isManualDateString,
  isManualDateStringSlashedDMY,
  isManualDateStringSlashedMDY,
  isManualDateStringSlashedMY,
  isValidAuthVerificationCode,
};
