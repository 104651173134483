import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { waitForVar } from '@pluralcom/plural-web-utils';

import environment from '../environment/environment';
import logger from '../logger/logger';
import cookiesHelpers from '../cookiesHelpers/cookiesHelpers';
import localStorageHelpers from '../localStorageHelpers';
import removeElementFromDom from '../uiDomHelpers/removeElementFromDom';

/**
 * Sets the Canny user.
 * To be called upon authentication and upon opening the website by an authenticated user
 */
const setUser = (
  user,
  {
    roles = [],
  }: {
    /** roles */
    roles: Array<string>;
  } = { roles: [] },
) => {
  if (!user) {
    return;
  }
  (window as any).Canny('identify', {
    appID:
      process.env.NEXT_PUBLIC_CANNY_APP_ID ||
      process.env.REACT_APP_CANNY_APP_ID,
    user: omitBy(
      {
        // Replace these values with the current user's data
        id: user.id,
        email: user.email,
        name:
          user.name ||
          `${user.first_name} ${user.last_name}`.trim() ||
          undefined,
        // These fields are optional, but recommended:
        avatarURL: user?.avatar?.thumbnail,
        userID: user.id,
        created: new Date(user.created_at).toISOString(),
        isAdmin: roles?.includes('admin'),
        // Extra fields
        alias: user.username,
        customFields: {
          // system fields
          env: environment.getHostEnv(),
        },
      },
      isEmpty,
    ),
  });
};

/**
 * Initializes Canny.io
 */
const init = () => {
  /** Setup */
  if (!environment.isProduction()) {
    return;
  }
  /** Mixpanel object - no destructuring to ensure reference exists */
  waitForVar('Canny')
    .then(() => {
      if (!(window as any).Canny) {
        return;
      }
      setUser(localStorageHelpers.getUser(), {
        roles: localStorageHelpers.getUserRoles(),
      });
    })
    .catch((err) => {
      logger.error('Error waiting for Canny', err);
    });
};

/** Fully Resets the canny user's data */
const reset = () => (window as any).Canny?.('reset');

/** Uninitializes the module and removes script and cookies */
const unInit = () => {
  cookiesHelpers.deleteCookie(`TODO`); // @todo @engineer - find the correct cookies/localStorage data and delete it
  removeElementFromDom(document.getElementById('script-canny'));
};

/**
 * Canny URL
 */
const CANNY_URL = environment.isProduction()
  ? 'https://pluralcom.canny.io'
  : 'https://plural-dev.canny.io';

export default {
  CANNY_URL,
  init,
  setUser,
  reset,
  unInit,
};
