import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { useMedia } from 'use-media';
import classNames from 'classnames';

import { ButtonLink, Card } from '@pluralcom/blueprint';

import { Header, MediaSection } from './components';
import styles from './SkillCardMini.module.scss';
import { responsive } from '../../utils';

interface SkillCardMiniProps {
  /** skill */
  skill: {
    /** skill ID */
    id: string;
    /** skill likes */
    likes: {
      /** skill likes ID */
      id: string;
      /** viewer has liked */
      viewerHasLiked: boolean;
    };
  };
  /** is owner */
  isOwner?: boolean;
  /** parent ID */
  parentID?: string;
  /** total count */
  totalCount?: number;
  /** own profile */
  ownProfile?: object;
  /** size */
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
}

const CARD_SIZE_MAP = {
  xs: 'xs',
  sm: 'xs',
  md: 'xs',
  lg: 'lg',
  xl: 'lg',
  xxl: 'lg',
  xxxl: 'lg',
};

/**
 *
 * FIGMA v0.0.2
 * SkillCardMini component.
 */
const SkillCardMini = ({
  isOwner,
  skill,
  parentID,
  totalCount,
  ownProfile,
  size,
  ...rest
}: SkillCardMiniProps) => {
  const isLg = useMedia({ minWidth: responsive.minDeviceWidthLG });
  const cardSize = CARD_SIZE_MAP[size];
  const screen_size = cardSize ?? (!isLg ? 'xs' : 'lg');

  return (
    <ButtonLink {...rest}>
      <Card
        className={classNames([
          styles['main-container'],
          styles[`main-container-${screen_size}`],
        ])}
        borderRadiusType={
          screen_size === 'xs' ? 'border-radius-type-3' : 'border-radius-type-5'
        }
        uiType="border-elevation"
      >
        <MediaSection isOwner={isOwner} skill={skill} size={screen_size} />
        <Header
          isOwner={isOwner}
          skill={skill}
          parentID={parentID}
          totalCount={totalCount}
          user={ownProfile}
          size={screen_size}
        />
      </Card>
    </ButtonLink>
  );
};

export { SkillCardMini as PureSkillCardMini };
export default createFragmentContainer(SkillCardMini, {
  skill: graphql`
    fragment SkillCardMini_skill on Skill
    @argumentDefinitions(
      isSkillCardPreview: { type: "Boolean", defaultValue: false }
      isSkillCardLean: { type: "Boolean", defaultValue: false }
    ) {
      id
      ...SkillCardMiniHeader_skill
        @arguments(
          isSkillCardPreview: $isSkillCardPreview
          isSkillCardLean: $isSkillCardLean
        )
      ...SkillCardMiniMediaSection_skill
    }
  `,
});
