/**
 * @generated SignedSource<<cf2235ce98e3ee1d598c5d5afaa82266>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AppleAuthClient = "ios" | "web" | "%future added value";
export type SigninAppleInput = {
  client?: AppleAuthClient | null;
  clientMutationId?: string | null;
  code?: string | null;
  first_name?: string | null;
  id_token: string;
  last_name?: string | null;
  nonce?: string | null;
  real_user_status?: number | null;
  with_auth?: boolean | null;
};
export type SigninAppleMutation$variables = {
  input: SigninAppleInput;
};
export type SigninAppleMutation$data = {
  readonly signinApple: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly intercom_hash: string | null;
    readonly justSignedUp: boolean | null;
    readonly profile: {
      readonly avatar: {
        readonly id: string;
        readonly smallThumbnail: string | null;
      } | null;
      readonly first_name: string | null;
      readonly id: string;
      readonly last_name: string | null;
      readonly name: string | null;
      readonly profile_color: string | null;
      readonly username: string | null;
    } | null;
    readonly roles: ReadonlyArray<string | null> | null;
  } | null;
};
export type SigninAppleMutation = {
  response: SigninAppleMutation$data;
  variables: SigninAppleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profile_color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "smallThumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roles",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "intercom_hash",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "justSignedUp",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SigninAppleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SigninApplePayload",
        "kind": "LinkedField",
        "name": "signinApple",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SigninAppleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SigninApplePayload",
        "kind": "LinkedField",
        "name": "signinApple",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "375cde133a52679dec300cf3331ff2ae",
    "id": null,
    "metadata": {},
    "name": "SigninAppleMutation",
    "operationKind": "mutation",
    "text": "mutation SigninAppleMutation(\n  $input: SigninAppleInput!\n) {\n  signinApple(input: $input) {\n    profile {\n      id\n      username\n      name\n      first_name\n      last_name\n      profile_color\n      avatar {\n        id\n        smallThumbnail\n      }\n    }\n    roles\n    intercom_hash\n    justSignedUp\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "acd2e74054943ffbcde6a73b15dae770";

export default node;
